import { merge } from "lodash";
import { PickByValue } from "../types";
export { merge } from "lodash";

export const deepmerge = merge;

export function clone<T extends Object>(o: T): T {
  return Array.isArray(o)
    ? [...o]
    : o instanceof Date
    ? new Date(o.getTime())
    : o && typeof o === "object"
    ? Object.create(Object.getPrototypeOf(o), Object.getOwnPropertyDescriptors(o))
    : o;
}

export function deepclone<T>(o: T): T {
  return Array.isArray(o)
    ? o.map((item) => deepclone(item))
    : o instanceof Date
    ? new Date(o.getTime())
    : o && typeof o === "object"
    ? Object.getOwnPropertyNames(o).reduce((o, prop) => {
        const descriptor = Object.getOwnPropertyDescriptor(o, prop);
        if (undefined !== descriptor) Object.defineProperty(o, prop, descriptor);
        o[prop] = deepclone(o[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(o)))
    : o;
}

export function diff<T extends Object>(a: T, b: T) {
  return Object.entries(b).reduce((acc: { [key: string]: any }, [key, val]) => {
    // include if types don't match
    if (!Object.prototype.hasOwnProperty.call(a, key) || typeof a[key] !== typeof val) {
      acc[key] = val;
      return acc;
    }

    // include if array elements don't match
    if (Array.isArray(val)) {
      if (
        a[key].length !== val.length ||
        !a[key].every((i: any) => val.includes(i)) ||
        !val.every((i: any) => a[key].includes(i))
      ) {
        acc[key] = val;
      }
      return acc;
    }

    // diff nested objects
    if (val !== null && typeof val === "object") {
      const obj = diff(a[key], val);
      if (Object.keys(obj).length) {
        acc[key] = obj;
      }
      return acc;
    }

    // include mismatched values
    if (a[key] !== val) acc[key] = val;

    return acc;
  }, {});
}

export function same<T extends object>(a: T, b: T) {
  return !Object.keys(diff(a, b)).length;
}

export function walk(path: string, obj: any) {
  if (!path || !obj) return undefined;
  return path.split(".").reduce((o, i) => (!!o && !!i ? o[i] : undefined), obj);
}

export function patch(path: string, value: any) {
  return path
    .split(".")
    .reverse()
    .reduce((a, k) => ({ [k]: a }), value);
}

export function setValue<T = any>(obj: T, path: string, value: any): T {
  const cloned = clone(obj);
  const pList = path.split(".");
  const key: any = pList.pop();
  const pointer: any = pList.reduce((accumulator: T, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, cloned);
  pointer[key] = value;
  return cloned;
}

export function omit(keys: string[], obj: { [key: string]: any }) {
  if (!keys?.length) return obj;
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (!keys.includes(k)) acc[k] = v;
    return acc;
  }, {});
}

export const removeEmpty = (obj: { [key: string]: any }) => {
  Object.keys({ ...obj }).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export function hash(obj: Object) {
  if (!obj) return;
  return Array.from(JSON.stringify(obj)).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
}

export function partialEquals<T>(object: T, partialObject: T) {
  return Object.keys(partialObject).every((k1) => partialObject[k1] === object[k1]);
}

/**
 * Takes an array of objects and creates a normalized map of them against key idKey
 * @param arr an array of objects
 * @param idKey a key in each object that can be used as an id
 */
export function normalize<T>(arr: T[], idKey: PickByValue<T, string>): Record<string, T>;
export function normalize<T>(arr: T[], idKey: PickByValue<T, number>): Record<number, T>;
export function normalize<T>(arr: T[], findId: (item: T) => string): Record<string, T>;
export function normalize<T>(arr: T[], findId: (item: T) => number): Record<number, T>;
export function normalize<T>(
  arr: T[],
  idKeyOrFindId: PickByValue<T, string | number> | ((item: T) => string | number)
): Record<string, T> {
  const findId = typeof idKeyOrFindId === "function" ? idKeyOrFindId : (item) => item[idKeyOrFindId];
  return arr.reduce((acc, obj) => {
    acc[findId(obj)] = obj;
    return acc;
  }, {} as Record<string, T>);
}
